import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { ProductorService } from '../../../../services/productor.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';

import { AdvancePayment } from '../../../../models/advancePayment';
import { CoffeeFarmer } from '../../../../models/productor';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as config from '../../../../services/advancePayment-config';
import { differenceInDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
@Component({
  selector: 'app-advance-payment-edit',
  templateUrl: './advance-payment-edit.component.html',
  styleUrls: ['./advance-payment-edit.component.css'],
})
export class AdvancePaymentEditComponent implements OnInit, AfterViewInit {

  public title = 'Editar Anticipo';
  public availableCoffeeFarmer = [];
  public filteredOptionsCF = [];
  public form: FormGroup;
  public totalDays = 0;
  public currentHarvest;
  public document_types: string[] = ['Pagaré', 'Recibo'];
  public types: string[] = ['Pre-Cosecha', 'Café en Depósito', 'Crédito a largo plazo', 'Crédito a Corto plazo', 'Anticipo por Trabajo'];
  public payment_types: string[] = ['Cheque', 'Tranferencia', 'Readecuacion', 'Efectivo', 'Insumos'  ];
  public projected = false;
  public company = '';
  public config = JSON.parse(JSON.stringify({}));
  public date1 = new FormControl(new Date());
  public await = false;
  public alertMessage = '';
  public currency;
  public currentBalance = 0;
  public currentDate = new Date();
  public lastAdvancePayment: any;
  public lastAdvancePaymentId = '';
  public coffeefarmerId;

  private commercial_date = false;
  private grace = false;

  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _advancePaymentService: AdvancePaymentService,
    private _productorService: ProductorService,
    public dialogRef: MatDialogRef<AdvancePaymentEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentHarvest = this._userService.getCompany().harvest;
    this.company = this._userService.getCompany()._id;
    this.config = config.getConfig(this.company);
    console.log('ToEdit: ', this.data);
    if (this.config.special_options) {
      this.payment_types = this.payment_types.concat(this.config.payment_types);
      this.types = this.types.concat(this.config.types);
    }
  }

  ngOnInit() {
    this.createForm();
    this.currency = GLOBAL.currency;
    this.await = true;
    this.coffeefarmerId = this.data.coffeefarmer;
    const date = {
      i_porcentNumber: this.data.i_porcent * 100,
      // coffeefarmer: this.data.coffeefarmer._id
    };
    this.data = Object.assign(this.data, date);
    this.form.patchValue(this.data);
    this.getDataFromServices();
  }

  getCurrentBalance(id) {
    if (id) {
      if (this.company === '62cfb754502565c8e04a161c') {
        this._advancePaymentService.getAdvancePaymentPerCoffeeFarmer(id).subscribe(res => {
          
            const last: any = _.last(res);
            this.lastAdvancePayment = last;
            this.lastAdvancePaymentId = last._id;
            this.currentBalance = last.total_balance;
            this.currentDate = last.date;
            this.calculateDiffDaysApc();
        });
      }
    }
  }

  ngAfterViewInit(): any {
    setTimeout(() => this.listenersFormValues(), 100);
    setTimeout(() => this.form.get('type').setValue(this.data.type), 200);
    setTimeout(() => this.calculateInterest(), 500);
  }

  getDataFromServices(): void {
    this._productorService.getProductores().subscribe(producers => {
      this.availableCoffeeFarmer = producers as CoffeeFarmer[];
      this.filteredOptionsCF = producers as CoffeeFarmer[];
    });
  }

  listenersFormValues(): void {
    this.form.get('coffeefarmerName').valueChanges.subscribe((value: string) => {
      this.filteredOptionsCF = this.availableCoffeeFarmer
        .filter((productor: CoffeeFarmer) => productor.name
          .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value
            .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('mount').valueChanges.subscribe((value: string) => {
      const mount = Number(value);
      if (
          !isNaN(mount) &&
          !isNaN(this.getFormValue('i_porcentNumber')) &&
          !isNaN(this.totalDays)
        ) {
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
      }
    });

    this.form.get('i_porcentNumber').valueChanges.subscribe(value => {
      if (!isNaN(value)) {
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
      }
    });

    this.form.get('grace_days').valueChanges.subscribe(value => {
      if (value >= 0) {
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
      }
    });

    this.form.get('type').valueChanges.subscribe((type: string) => {
      this.projected = this.config.projected ?
        this.config.projectedTypes.some(typeConfig => typeConfig === type) :
        this.projected;

      this.commercial_date = this.config.commercial_date ?
        this.config.commercialTypes.some(typeConfig => typeConfig === type) :
        this.commercial_date;

      this.grace = this.config.grace ?
        this.config.graceTypes.some(typeConfig => typeConfig === type) :
        this.grace;
      console.log(`proyectado: ${this.projected} | commercial_date: ${this.commercial_date} | gracia: ${this.grace}`);
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
    });

    this.form.get('date').valueChanges.subscribe(() => {
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
    });

    this.form.get('date_end').valueChanges.subscribe(() => {
          if (this.company === '62cfb754502565c8e04a161c') {
            this.calculateInterestApc();
          } else {
            this.calculateInterest();
          }
    });
  }

  /*
    * Calcula los dias de diferencia entre las fecha tomando en cuenta la configuracion del perfil y del usuario.
    * Se calcula desde el dia de inicio (incluido) hasta dia final (no incluido) o viceversa.
    * Si es un anticipo proyectado, se calcula los dias con meses comerciales fecha inicio -> fecha final.
    * Si el anticipo no permite meses comerciales, se hace calculo de dias normal, fecha inicio -> fecha hoy.
    * Si el anticipo permite meses comerciales, se calcula los dias de fecha inicio hasta hoy.
    @return number : Cantidad de dias calculados.
  */
  calculateDiffDays(): JSON {
    this.checkDateConstraint();
    let days = 0;
    const daysData = JSON.parse(JSON.stringify({}));
    const dateIn = new Date(this.form.get('date').value);
    dateIn.setHours(0, 0, 0, 0);
    console.log('Date1: ', dateIn, 'date2: ', this.form.get('date_end').value);
    if (this.form.get('date').valid && this.form.get('date_end').valid) {
      if (this.projected) {
        days = this.calculateDaysCommercialYear(dateIn, new Date(this.getFormValue('date_end')));
      } else {
        days = !this.commercial_date ?
          this.getDays(dateIn, moment()['_d']) :
          this.calculateDaysCommercialYear(new Date(this.getFormValue('date')), moment()['_d']);
      }
    } else { console.error ('Fecha invalida'); }
    daysData['totalDays'] = days;
    if (this.grace) {
      if (this.getFormValue('grace_days') >= 0) {
        const grace = days - this.getFormValue('grace_days');
        daysData['days'] = grace > 0 ? grace : 0;
      }
    } else {
      daysData['days'] = days;
    }

    console.log('daysData: ', daysData);
    return daysData;
  }

  /*
    * Calcula los intereses segun la configuracion y los datos ingresados por el usuario.
    * Se calculan los dias de acuerdo a la configuracion predeterminada del perfil y los datos del usuario.
  */
  calculateInterest(): void {
    if (this.await) {
      const daysData: any = this.calculateDiffDays();
      this.totalDays = daysData.totalDays;
      const days = daysData.days;
      const i = this.getFormValue('i_porcentNumber') / 100;
      const mount = Number(String(this.form.get('mount').value).split(',').join(''));
      const mi = mount * i;
      const tm = mi / 12;
      const ixd = parseFloat((tm / 30).toFixed(8));

      this.setFormValue('interest', ixd);
      this.setFormValue('total_interest', days === 0 ? 0 : ixd * days);
      this.setFormValue('total_balance', mount + this.getFormValue('total_interest'));
    }
  }

  calculateDiffDaysApc(): JSON {
    // this.checkDateConstraint();
    let days = 0;
    this.projected = true;
    const daysData = JSON.parse(JSON.stringify({}));
    const dateIn = new Date(this.currentDate);
    dateIn.setHours(0, 0, 0, 0);

    console.log(dateIn );

    if (dateIn && this.form.get('date').valid) {
      days = this.getDays(dateIn, this.getFormValue('date'));
      // days = this.calculateDaysCommercialYear(dateIn, this.getFormValue('date'));
    } else { console.error ('Fecha invalida'); }
    daysData['totalDays'] = days;
    return daysData;
  }

  calculateInterestApc(): void {
    const daysData: any = this.calculateDiffDaysApc();

    console.log(daysData);
    this.totalDays = daysData.totalDays;
    const days = daysData.totalDays;
    const i = this.getFormValue('i_porcentNumber') / 100;
    const mount = this.form.get('mount').value;
    const newMount = mount + this.currentBalance;
    const mi = this.currentBalance * i;
    const dayliInterest = parseFloat((mi / 360).toFixed(8));
    const newInterest = dayliInterest * days;

    this.setFormValue('interest', dayliInterest);
    this.setFormValue('total_interest', newInterest);
    if (this.currentBalance > 0) {
      this.setFormValue('total_balance', mount + this.currentBalance + this.getFormValue('total_interest'));
    } else {
      this.setFormValue('total_balance', mount);
    }
  }
  submitForm(): void {
    const advancePayment: any = Object.assign(this.data, this.form.getRawValue());
    advancePayment.days = this.totalDays;
    advancePayment.mount = Number(String(advancePayment.mount).split(',').join(''));
    advancePayment.current_capital = advancePayment.mount;
    advancePayment.projected = this.projected;
    advancePayment.commercial_date = this.commercial_date;
    advancePayment.harvest = this.currentHarvest;
    advancePayment.i_porcent = this.getFormValue('i_porcentNumber') / 100;
    // advancePayment.grace = (this.grace && advancePayment.grace_days > 0) ? true : false;
    // advancePayment.grace_days = this.grace ? advancePayment.grace_days : 0;
    if (this.company === '62cfb754502565c8e04a161c') {
      advancePayment.grace = true;
      advancePayment.grace_days = 1000000000;
      advancePayment.last = true;
      if (this.lastAdvancePaymentId !== '') {
        this._advancePaymentService.editAdvancePayment(this.lastAdvancePaymentId, {last: false}).subscribe();
      }
    } else {
      advancePayment.grace = (this.grace && advancePayment.grace_days > 0) ? true : false;
      advancePayment.grace_days = this.grace ? advancePayment.grace_days : 0;
    }
    console.log('Anticipo a editar: ', advancePayment);
    this._advancePaymentService.editAdvancePayment(advancePayment._id, advancePayment).subscribe( response => {
      this.dialogRef.close();
    }, error => console.error('Error al agregar anticipo: ', error));
  }

  createForm(): void {
    this.form = this.fb.group(Object.assign({
      date:             ['', Validators.required],
      coffeefarmer:     ['', Validators.required],
      date_end:         ['', Validators.required],
      document_type:    ['', Validators.required],
      payment_type:     ['', Validators.required],
      type:             ['', Validators.required],
      mount:            [0, Validators.required],
      i_porcentNumber:  [0, Validators.required],
      days:             [0],
      total_balance:    [0],
      grace_days:       [0],
      coffeefarmerName: [''],
      interest:         [0],
      observations:     [''],
      total_interest:   [0],
      payment_status:   ['Pendiente'],
    }, this.company === '5f50fdc413c3ab1f4454c17d' || this.company === '5d7742116bc1d64060abfc9b' ? {i_arrears: [0], warranty: ['']} : {}));
  }

  // => --------------------------------------------- FUNCIONES AUXILIARES ------------------------------------------------
  /*
    * Estable el valor de un propiedad en el form group.
    @param key: Propiedad del group group a establecer.
    @param value: El valor a establecer.
  */
  setFormValue(key: string, value: any): void {
    this.form.get(key).setValue(value);
  }

  /*
    * Obtiene el valor de un propiedad en el form group.
    @param key: Propiedad del group group a obtener.
  */
  getFormValue(key: string): any {
    return this.form.get(key).value;
  }

  /*
    * Permite el calculo de dias con meses comerciales, sumando 30 dias por cada mes hasta que el mes y a?o de la fecha
    * final sean iguales.
    @param date_in: Fecha de inicio.
    @param date_out: Fecha final.
    @return cantidad de dias entre las fechas.
  */
  calculateDaysCommercialYear(date_in: any, date_out: any): number {
    // yyyy mm dd
    const date1 = date_in;
    const date2 = date_out;
    const splitedDate1 = [date1.getFullYear(), date1.getMonth() + 1, date1.getDate()];
    const splitedDate2 = [date2.getFullYear(), date2.getMonth() + 1, date2.getDate()];
    let days = 0;

    // * Mientras el mes y el a?o sea diferente, que sume 30 cada mes.
    while (
      (splitedDate1[1] !== splitedDate2[1]) ||
      (splitedDate1[0] !== splitedDate2[0])
      ) {
      days += 30;
      const currentMonth = splitedDate1[1];
      splitedDate1[1] = currentMonth === 12 ? 1 : currentMonth + 1;
      splitedDate1[0] = currentMonth === 12 ? splitedDate1[0] + 1 : splitedDate1[0];
    }
    // * Usar 31 - splitedDate1[2] si se incluye el dia de expiracion.
    const restDays = splitedDate2[2] <= 30 ? splitedDate2[2] - splitedDate1[2] : 30 - splitedDate1[2];
    days += restDays;
    return days;
  }

  /*
    * Obtiene los dias de diferencia entre 2 fechas dadas.
    @param date_in: Dia inicial.
    @param date_out: Dia final.
    @return number: Los dias de diferencia.
  */
  getDays(date_in: any, date_out: any): number {
    const pre2 = zonedTimeToUtc(new Date(date_out), 'America/Chicago');
    const diffDays = Math.abs(differenceInDays(date_in, pre2));
    return diffDays;
  }

  checkDateConstraint(): void {
    const now = new Date();
    const date1 = new Date(this.form.get('date').value);
    const date2 = new Date(this.form.get('date_end').value);
    let status = true;
    if (date1.getTime() > now.getTime()) {
      console.error('Fecha inicio es mayor a hoy');
      this.form.controls['date'].setErrors({'incorrect': true});
      status = false;
      this.alertMessage = 'La fecha inicio es superior al dia actual.';
    } else if (date2.getTime() < date1.getTime()) {
      this.form.controls['date_end'].setErrors({'incorrect': true});
      console.error('Fecha final es menor a fecha inicial');
      status = false;
      this.alertMessage = 'La fecha de expiracion es inferior a la fecha inicio.';
    } else {
      this.alertMessage = '';
      this.form.controls['date'].clearValidators();
      this.form.controls['date'].setValidators(Validators.required);
      this.form.controls['date_end'].clearValidators();
      this.form.controls['date_end'].setValidators(Validators.required);
    }
  }

}
